import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';

export default function PrivacyPolicy({ data, location }) {
  const pageData = data.privacyPolicy;

  return (
    <Layout bodyClass="page-privacy-policy">
      <SEO
        title={pageData.frontmatter.title}
        image={null}
        url={location.href}
      />
      <div className="container pt-6">
        <div className="col-12 d-flex justify-content-between">
          <h1>{pageData.frontmatter.title}</h1>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="col-12">
          <div
            className="reset-list-styles"
            dangerouslySetInnerHTML={{ __html: pageData.html }}
          />
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    privacyPolicy: markdownRemark(
      fileAbsolutePath: { regex: "/content/privacy-policy.md/" }
    ) {
      html
      frontmatter {
        title
      }
      excerpt
    }
  }
`;
